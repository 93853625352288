<template>
  <div id="formulario-edit-info">
    <vs-popup title="Informar o CPF ou CNPJ do Cliente" :active.sync="popImportarCliente.exibir">
        <vs-row class="mb-2">
            <vs-col vs-type="flex" vs-justify="center" vs-w="12"> 
                <input class="vs-input--input w-full" type="text" name="txtCPFCNPJ" id="txtCPFCNPJ" ref="txtCPFCNPJ" v-on:keyup.13="importarCliente($refs.txtCPFCNPJ.value)"/> 
                <vs-button color="primary" type="border" icon="search" class="ml-2" @click="importarCliente($refs.txtCPFCNPJ.value)"></vs-button>                
            </vs-col>
        </vs-row>
    </vs-popup> 
    <vs-popup title="Informar o CNPJ do Pré-Cadstro" :active.sync="popImportarPreCadastro.exibir">
        <vs-row class="mb-2">
            <vs-col vs-type="flex" vs-justify="center" vs-w="12"> 
                <input class="vs-input--input w-full" type="text" name="preCadastroCNPJ" id="preCadastroCNPJ" ref="preCadastroCNPJ" v-on:keyup.13="importarCliente($refs.preCadastroCNPJ.value)"/> 
                <vs-button color="primary" type="border" icon="search" class="ml-2" @click="importarPreCadastro($refs.preCadastroCNPJ.value)"></vs-button>                
            </vs-col>
        </vs-row>
    </vs-popup> 
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <vx-card>
        <div class="vx-row mb-4">
            <div class="vx-col md:w-1/2 w-full">                    
                <vs-button class="ml-auto mt-2" size="small" @click="gravar" color="success" v-if="habilitarGravacao">Gravar</vs-button>
                <vs-button class="ml-4 mt-2" size="small" @click="excluir" v-show="false" color="danger">Excluir</vs-button>            
                <vs-button class="ml-4 mt-2" size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>             
            </div>
        </div>

        <vs-tabs alignment="fixed" style="min-height: calc(70vh)">
            <vs-tab label="Dados Básicos">
                <div class="vx-row">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Código</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">CPF/CNPJ</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Status</label></div>     
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/3 flex">
                        <vs-button size="small" type="filled" color="primary" @click="abrirPopUpImportarCliente" icon="search" v-if="!data_local.hasOwnProperty('id')" class="mr-2">Importar do SAC</vs-button>
                        <vs-button size="small" type="filled" color="primary" @click="abrirPopUpImportarPreCadastro" icon="search" v-if="!data_local.hasOwnProperty('id')">Pré-Cadastro</vs-button>
                        <vs-input class="ml-2 w-full" v-model="data_local.codigoInterno" :maxlength="60"/> 
                    </div>     
                    <div class="vx-col w-1/3 flex">
                        <v-select                                               
                            :options="CONSTANTE_TIPO_CLIENTE" 
                            v-model="data_local.tipoCliente"    
                            :clearable="false"
                            :reduce="r => r.data" 
                            style="min-width: 80px"                                                      
                            class="vs-input--label mr-2">
                        </v-select> 
                        <vs-input class="w-full" v-model="data_local.CNPJCPF" :maxlength="60"/> 
                    </div>  
                    <div class="vx-col w-1/3 flex">
                        <v-select                                               
                            :options="CONSTANTE_STATUS_CLIENTE" 
                            v-model="data_local.status"    
                            :clearable="false"
                            :reduce="r => r.data" 
                            class="vs-input--label w-full">
                        </v-select> 
                    </div>     
                </div>     

                <div class="vx-row mt-2">
                    <div class="vx-col w-1/2"><label class="vs-input--label">Razão Social</label></div>     
                    <div class="vx-col w-1/2"><label class="vs-input--label">Nome</label></div>                 
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/2">
                        <vs-input class="w-full" v-model="data_local.razaoSocial" :maxlength="255"/> 
                    </div>     
                    <div class="vx-col w-1/2">
                        <vs-input class="w-full" v-model="data_local.nome" :maxlength="255"/>   
                    </div>     
                </div>       

                <div class="vx-row mt-2">
                    <div class="vx-col w-5/6"><label class="vs-input--label">Endereço</label></div>     
                    <div class="vx-col w-1/6"><label class="vs-input--label">Número</label></div>     
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-5/6">
                        <vs-input class="w-full" v-model="data_local.endereco" :maxlength="500"/> 
                    </div>                 
                    <div class="vx-col w-1/6">
                        <vs-input class="w-full" v-model="data_local.numeroEndereco" :maxlength="45"/> 
                    </div>                 
                </div>
                <div class="vx-row mt-2">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Complemento</label></div>         
                    <div class="vx-col w-1/3"><label class="vs-input--label">Bairro</label></div>         
                    <div class="vx-col w-1/3"><label class="vs-input--label">CEP</label></div>         
                </div>
                <div class="vx-row">
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.complementoEndereco" :maxlength="100"/> 
                    </div>
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.bairro" :maxlength="100"/> 
                    </div>
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.cep" :maxlength="9"/> 
                    </div>     
                </div>
                <div class="vx-row mt-2">                    
                    <div class="vx-col w-1/2"><label class="vs-input--label">Cidade</label></div>     
                    <div class="vx-col w-1/2"><label class="vs-input--label">Estado</label></div>                 
                </div>    
                <div class="vx-row">                    
                    <div class="vx-col w-1/2">
                        <vs-input class="w-full" v-model="data_local.cidade" :maxlength="255"/> 
                    </div>     
                    <div class="vx-col w-1/2">
                        <v-select                                               
                            :options="CONSTANTE_UFS" 
                            v-model="data_local.estado"    
                            :clearable="false"
                            :reduce="r => r.data"                                                                         
                            class="vs-input--label mr-2">
                        </v-select>   
                    </div>     
                </div>    

                
            </vs-tab>
            <vs-tab label="Contatos">
                <div class="vx-row mt-2">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Nome Contato Financeiro</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Telefone Contato Financeiro</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">E-mail Contato Financeiro</label></div>          
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.nomeContato1" :maxlength="255"/> 
                    </div>         
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.telefone" :maxlength="255"/> 
                    </div>         
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.email" :maxlength="255"/> 
                    </div>   
                </div>   

                <div class="vx-row mt-2">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Nome Contato 2</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Telefone Contato 2</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">E-mail Contato 2</label></div>     
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.nomeContato2" :maxlength="255"/> 
                    </div>         
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.telefone2" :maxlength="255"/> 
                    </div>       
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.email2" :maxlength="255"/> 
                    </div>         
                    
                </div>  
                <div class="vx-row mt-2">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Nome Contato 3</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Telefone Contato 3</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">E-mail Contato 3</label></div>     
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.nomeContato3" :maxlength="255"/> 
                    </div>         
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.telefone3" :maxlength="255"/> 
                    </div>    
                    <div class="vx-col w-1/3">
                        <vs-input class="w-full" v-model="data_local.email3" :maxlength="255"/> 
                    </div> 
                </div>
                <div class="vx-row mt-2">
                    <div class="vx-col w-1/3"><label class="vs-input--label">Nome Contato Frota</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label">Telefone Contato Frota</label></div>                 
                    <div class="vx-col w-1/3"><label class="vs-input--label">Email Contato Frota</label></div>                 
                </div> 
                <div class="vx-row mt-2">                    
                    <div class="vx-col w-1/3"><vs-input  class="w-full" v-model="data_local.nomeResponsavelFrota" :maxlength="255"/></div>     
                    <div class="vx-col w-1/3"><vs-input  class="w-full" v-model="data_local.telefonesResponsavelFrota" :maxlength="255"/></div>                 
                    <div class="vx-col w-1/3"><vs-input  class="w-full" v-model="data_local.emailResponsavelFrota" :maxlength="255"/></div>                 
                </div>
                <div class="vx-row mt-2">                    
                    <div class="vx-col w-1/3"><label class="vs-input--label">Técnico</label>   </div>
                </div>
                <div class="vx-row mt-2">                    
                    <div class="vx-col w-1/3">
                        <v-select       
                            label="nome"               
                            :options="listaTecnicos" 
                            :appendToBody="true" 
                            :reduce="t => t.id"                                     
                            v-model="data_local.idTecnicoPreferencial"                          
                            class="w-full vs-input--label">                    
                        </v-select>             
                    </div>                
                </div>
          
            </vs-tab>
            <vs-tab label="Senhas">
                <div class="vx-row mt-2">
                    <div class="vx-col w-1/2"><label class="vs-input--label">Senha de Acesso</label></div>     
                    <div class="vx-col w-1/2"><label class="vs-input--label">Senha para Bloqueio</label></div>                 
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/2">
                        <vx-input-group class="w-full" >
                            <vs-input class="w-full" v-model="data_local.novaSenha" :maxlength="255"/> 
                            <template slot="append">
                                <div class="append-text btn-addon">
                                    <vs-button color="dark" icon-pack="feather" icon="icon-send" v-if="false">Reset Senha</vs-button>  
                                    <vs-button color="primary" icon-pack="feather" icon="icon-send" @click="reenviarEmailBoaVindas" v-if="data_local.id">Reenvia Email</vs-button>                                                                                              
                                </div>
                            </template>
                        </vx-input-group>   
                    </div>     
                    <div class="vx-col w-1/2">
                        <vx-input-group class="w-full" >
                            <vs-input class="w-full" v-model="data_local.senhaBloqueio" :maxlength="255"/>
                            <template slot="append">
                                <div class="append-text btn-addon">
                                    <vs-button color="primary" icon-pack="feather" icon="icon-send" @click="enviarEmailSenhaBloqueio" v-if="data_local.id">Enviar Senha</vs-button>                                                
                                </div>
                            </template>
                        </vx-input-group>   
                    </div>     
                </div>     

                <div class="vx-row mt-2">
                    <div class="vx-col w-1/2"><label class="vs-input--label">Login Serviço SMS</label></div>     
                    <div class="vx-col w-1/2"><label class="vs-input--label">Senha Serviço SMS</label></div>                 
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-1/2">
                        <vs-input class="w-full" v-model="data_local.loginServicoSMS" :maxlength="255"/> 
                    </div>     
                    <div class="vx-col w-1/2">
                        <vs-input class="w-full" v-model="data_local.senhaServicoSMS" :maxlength="255"/>   
                    </div>     
                </div>     

                <div class="vx-row mt-2">
                    <div class="vx-col w-full"><label class="vs-input--label">Senha Webservice</label></div>                 
                </div>    
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <vs-input class="w-full" v-model="data_local.senhaWebservice" readonly="true" /> 
                    </div>     
                </div>     
            </vs-tab>
            <vs-tab label="Contrato/Teste" style="height: calc(70vh)">
                <vs-checkbox v-model="data_local.bloquearCriacaoOrdemServico" class="w-full" :disabled="!temPermissaoLiberarCriacaoOS"><small>Bloquear Criação de Ordem de Serviço ?</small></vs-checkbox>                            
                <div class="vx-row mt-4">
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">Contrato ou Teste ?</label>
                    </div>  
                    <div class="vx-col w-1/4" v-if="tipoContratoTeste === 'contrato'">
                        <label class="vs-input--label mr-2">Status Contrato</label>
                    </div>  
                    <div class="vx-col w-1/4" v-if="tipoContratoTeste === 'contrato'">
                        <label class="vs-input--label mr-2">Tipo Contrato</label>
                    </div>  
                    <div class="vx-col w-1/4" v-if="tipoContratoTeste === 'teste'">
                        <label class="vs-input--label mr-2">Status Teste</label>
                    </div>  
                    <div class="vx-col w-1/2" v-if="tipoContratoTeste === 'teste'">
                        <label class="vs-input--label mr-2">Ação no fim do Teste</label>
                    </div>                      
                </div>   
                <div class="vx-row">
                    <div class="vx-col w-1/4">
                        <v-select                                               
                            :options="CONSTANTE_TIPO_CONTRATO_TESTE" 
                            v-model="tipoContratoTeste"    
                            :clearable="false"
                            :reduce="r => r.data"                                                                         
                            class="vs-input--label mr-2">
                        </v-select>   
                    </div>     
                    <div class="vx-col w-1/4" v-if="tipoContratoTeste  ===  'contrato'">
                        <v-select                                               
                            :options="CONSTANTE_STATUS_CONTRATO" 
                            v-model="data_local.statusContrato"    
                            :clearable="false"
                            :reduce="r => r.data"                                                                         
                            class="vs-input--label mr-2">
                        </v-select>   
                    </div>     
                    <div class="vx-col w-1/4" v-if="tipoContratoTeste === 'contrato'">
                        <v-select                                               
                            :options="CONSTANTE_TIPO_CONTRATO" 
                            v-model="data_local.tipoContrato"    
                            :clearable="false"
                            :reduce="r => r.data"                                                                         
                            class="vs-input--label mr-2">
                        </v-select>   
                    </div>  
                    <div class="vx-col w-1/4" v-if="tipoContratoTeste === 'teste'">
                        <v-select                                               
                            :options="CONSTANTE_STATUS_TESTE_CLIENTE" 
                            v-model="data_local.statusTeste"    
                            :clearable="false"
                            :reduce="r => r.data"                                                                         
                            class="vs-input--label mr-2">
                        </v-select>   
                    </div>  
                     <div class="vx-col w-1/2" v-if="tipoContratoTeste === 'teste'">
                         <v-select                                               
                            :options="CONSTANTE_ACAO_FIM_TESTE_CLIENTE" 
                            v-model="data_local.acaoFimTeste"    
                            :clearable="false"
                            :reduce="r => r.data"                                                                         
                            class="vs-input--label mr-2">
                        </v-select>
                    </div>    
                </div>  
                <div class="vx-row mt-2" v-if="tipoContratoTeste === 'contrato'">
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">Data de Início</label>
                    </div>  
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">N. Meses</label>
                    </div>                      
                    <div class="vx-col w-1/4" v-if="data_local.id">
                        <label class="vs-input--label mr-2">Data de Fim</label>
                    </div>  
                    <div class="vx-col w-1/4">
                    </div>
                </div>
                <div class="vx-row" v-if="tipoContratoTeste === 'contrato'">
                    <div class="vx-col w-1/4">                        
                        <flat-pickr v-model="data_local.dataInicioContratoEmMili" :config="configDatePicker"/>
                    </div>     
                    <div class="vx-col w-1/4">
                        <vs-input class="w-full" v-model="data_local.duracaoContrato" type="number"/> 
                    </div>                         
                    <div class="vx-col w-1/4 mt-2">
                        <p class="font-medium">{{data_local.dataFimContratoDMY}}</p>
                    </div>
                    <div class="vx-col w-1/4">
                    </div>
                </div>

                <div class="vx-row mt-2" v-if="tipoContratoTeste === 'contrato'">                                       
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">Dia do Vencimento</label>                        
                        <vs-input class="w-full" v-model="data_local.diaVencimento" type="number"/>                       
                    </div>  
                    <div class="vx-col w-1/2">
                        <label class="vs-input--label mr-2">Regra Faturamento</label>
                         <v-select                                               
                            :options="CONSTANTE_REGRA_FATURAMENTO_CLIENTE" 
                            v-model="data_local.regraFaturamento"    
                            :clearable="false"
                            :disabled="$store.state.AppActiveUser.idEmpresa !== 11"
                            :reduce="r => r.data"                                                                         
                            class="vs-input--label">
                        </v-select>  
                    </div>                      
                </div>

                <div class="vx-row mt-2" v-if="tipoContratoTeste === 'contrato' && data_local.id">
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">Representante Legal</label>
                    </div>  
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">CPF do Representante</label>
                    </div>  
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">Email aceite do contrato</label>
                    </div>  
                </div>    
                <div class="vx-row" v-if="tipoContratoTeste === 'contrato' && data_local.id">
                    <div class="vx-col w-1/4">                        
                        <vs-input class="w-full" v-model="data_local.nomeRepresentante" :maxlength="60"/> 
                    </div>  
                    <div class="vx-col w-1/4">                        
                        <vs-input class="w-full" v-model="data_local.cpfRepresentante" :maxlength="60"/> 
                    </div>  
                    <div class="vx-col w-1/4">
                        
                        <vs-input class="w-full" v-model="data_local.emailAceiteFormularioPedido" :maxlength="60"/> 
                    </div>  
                </div>   
                <div class="vx-row mt-2" v-if="tipoContratoTeste === 'teste'">
                    <div class="vx-col w-1/4">
                        <label class="vs-input--label mr-2">Data do Fim do Teste</label>
                    </div>                      
                </div>    
                <div class="vx-row" v-if="tipoContratoTeste === 'teste'">
                    <div class="vx-col w-1/4">                        
                        <flat-pickr v-model="data_local.dataFimTesteEmMili" :config="configDatePicker"/>
                    </div>                         
                </div>
            </vs-tab>
            <vs-tab label="Outros" style="height: calc(63vh); overflow-y: auto; overflow-x: hidden">
                 <div class="vx-row">
                    <div class="vx-col w-1/6">
                        <div class="vx-col w-1/2"><label class="vs-input--label mr-2">Versão</label></div>     
                    </div>  
                 </div>   
                 <div class="vx-row">
                    <div class="vx-col w-1/6">
                        <v-select                                               
                            :options="CONSTANTE_TIPO_VERSOES" 
                            v-model="data_local.versaoProduto"    
                            :clearable="false"
                            :reduce="r => r.data"                                                                         
                            class="vs-input--label mr-2">
                        </v-select>   
                    </div>     
                    <div class="vx-col w-5/6 flex items-center" v-if="data_local.versaoProduto === 'PRO'">
                        <vs-checkbox v-model="data_local.temControleFrota" class="w-full"><small>Controle de Frotas</small></vs-checkbox>
                        <vs-checkbox v-model="data_local.temControleViagem" class="w-full"><small>Controle de Viagens</small></vs-checkbox>
                        <vs-checkbox v-model="data_local.temRoteirizacao" class="w-full"><small>Roteirização</small></vs-checkbox>
                        <vs-checkbox v-model="data_local.temControlePneus" class="w-full"><small>Controle de Pneus</small></vs-checkbox>
                    </div>     
                </div>    

                <div class="vx-row mt-2">
                    <div class="vx-col w-1/3"><label class="vs-input--label mr-2">Envio de Avisos</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label mr-2">Monitorável por Central</label></div>     
                    <div class="vx-col w-1/3"><label class="vs-input--label mr-2">Controle de iButtons/RFID</label></div>     
                </div> 
                <div class="vx-row mt-2">
                    <div class="vx-col w-1/3">
                        <vs-checkbox v-model="data_local.enviarEmailAvisoEmLote" class="w-full"><small>Enviar um email diário com todos os avisos</small></vs-checkbox>                            
                    </div>
                    <div class="vx-col w-1/3">
                        <vs-checkbox v-model="data_local.monitoravelPorCentral" class="w-full"><small>Exibir os avisos no backend de monitoramento</small></vs-checkbox>                            
                    </div>
                    <div class="vx-col w-1/3">
                        <vs-checkbox v-model="data_local.permitirAlterarIButtons" class="w-full"><small>Permitir cadastrar/excluir ibuttons</small></vs-checkbox>                            
                    </div>
                </div>

                <div class="vx-row mt-2">
                    <div class="vx-col w-full"><label class="vs-input--label mr-2">Mensagem</label></div>     
                </div> 
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <vs-textarea v-model="data_local.mensagem" rows="2" />
                    </div>
                </div>

                <div class="vx-row mt-2">
                    <div class="vx-col w-full"><label class="vs-input--label mr-2">Texto Assinatura Minha Rota</label></div>     
                </div> 
                <div class="vx-row mt-2">
                    <div class="vx-col w-full">
                        <vs-textarea v-model="data_local.textoAssinaturaMinhaRotaPuro" rows="3" />
                    </div>
                </div> 

                <div class="vx-row mt-2">
                    <div class="vx-col w-1/4"><label class="vs-input--label mr-2">Incremento GMT</label></div>     
                    <div class="vx-col w-1/4"><label class="vs-input--label mr-2">Nota Máxima de Motoristas</label></div>                         
                    <div class="vx-col w-1/4"><label class="vs-input--label mr-2">Numeração Pedidos da Roteirização</label></div>     
                    <div class="vx-col w-1/4"><label class="vs-input--label mr-2">Nota Fiscal em Despesas</label></div>     
                </div> 
                <div class="vx-row">
                    <div class="vx-col w-1/4">
                        <vs-input class="w-full" v-model="data_local.incrementoGMT" type="number"/> 
                    </div>     
                    <div class="vx-col w-1/4">
                        <vs-input class="w-full" v-model="data_local.notaMaximaDeMotoristas" type="number"/>   
                    </div>                         
                    <div class="vx-col w-1/4">
                        <vs-checkbox v-model="data_local.numeracaoPedidoPorData" class="w-full"><small>Numeração única por data</small></vs-checkbox>                            
                    </div>
                    <div class="vx-col w-1/4">
                        <vs-checkbox v-model="data_local.permiteNotaDuplicadaEmDespesa" class="w-full"><small>Permite Nota Fiscal duplicada</small></vs-checkbox>                            
                    </div>
                </div>   
               
            </vs-tab>
            <vs-tab label="Jornada">                 
                 <div class="vx-row items-center">
                    <div class="vx-col w-1/3">
                        <label class="vs-input--label mr-2">Carga Horáraria Diária do Motorista</label>
                        <vs-input v-model="data_local.cargaHorariaDiariaTrabalhoMotorista" type="number" class="w-full"/>   
                    </div>
                    <div class="vx-col w-1/3">
                        <label class="vs-input--label mr-2">Número Máximo de Horas Extras</label>
                        <vs-input v-model="data_local.numeroMaximoHorasExtras" type="number" class="w-full"/>   
                    </div>
                    <div class="vx-col w-1/3"></div>
                </div>
                <div class="vx-row items-center mt-2">
                    <div class="vx-col w-1/3">
                        <vs-checkbox v-model="data_local.descontarTempoRefeicaoDaDirecao"><label class="vs-input--label mr-2">Descontar Tempo Refeição da Direção</label></vs-checkbox>
                    </div>     
                    <div class="vx-col w-1/3">
                        <vs-checkbox v-model="data_local.descontarTempoDescansoDaDirecao" class="w-full"><label class="vs-input--label mr-2">Descontar Tempo Descanso da Direção</label></vs-checkbox>
                    </div>     
                    <div class="vx-col w-1/3">
                        <vs-checkbox v-model="data_local.descontarTempoEsperaDaDirecao" class="w-full"><label class="vs-input--label mr-2">Descontar Tempo Espera da Direção</label></vs-checkbox>
                    </div>     
                </div>
                <div class="vx-row items-center mt-2">                    
                    <div class="vx-col w-1/3">
                        <vs-checkbox v-model="data_local.descontarTempoAbastecimentoDaDirecao" class="w-full"><label class="vs-input--label mr-2">Descontar Tempo Abastecimento da Direção</label></vs-checkbox>
                    </div>     
                    <div class="vx-col w-1/3">
                        <vs-checkbox v-model="data_local.descontarTempoManutencaoDaDirecao"><label class="vs-input--label mr-2">Descontar Tempo Manutenção da Direção</label></vs-checkbox>
                    </div>     
                    <div class="vx-col w-1/3">
                        <vs-checkbox v-model="data_local.somarTempoEsperaADirecao"><label class="vs-input--label mr-2">Somar Tempo Espera a Direção para Horas Extras</label></vs-checkbox>
                    </div>    
                </div>
            </vs-tab>
            <vs-tab label="Notificações">
                <vs-table :data="data_local.innerLista" class="tabelaComScroll">
                    <template slot="thead">
                        <vs-th sort-key="dataTipoAviso">Aviso</vs-th>
                        <vs-th>E-mail <vs-button size="small" color="rgb(134, 4, 98)" type="filled" class="ml-2 p-2" style="min-width: 45px" @click.stop="toggleBtnEmail_click">{{toggleBtnEmail}}</vs-button></vs-th>
                        <vs-th>SMS <vs-button size="small" color="rgb(134, 4, 98)" type="filled" class="ml-2 p-2" style="min-width: 45px" @click.stop="toggleBtnSMS_click">{{toggleBtnSMS}}</vs-button></vs-th>
                        <vs-th>Central <vs-button size="small" color="rgb(134, 4, 98)" type="filled" class="ml-2 p-2" style="min-width: 45px" @click.stop="toggleBtnCentral_click">{{toggleBtnCentral}}</vs-button></vs-th>
                        <vs-th>Push <vs-button size="small" color="rgb(134, 4, 98)" type="filled" class="ml-2 p-2" style="min-width: 45px" @click.stop="toggleBtnPush_click">{{toggleBtnPush}}</vs-button></vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                            <vs-td :data="tr.dataTipoAviso" v-if="tr.labelTipoAviso"><small>{{tr.labelTipoAviso}}</small></vs-td>
                            <vs-td :data="tr.dataTipoAviso" v-if="!tr.labelTipoAviso"><small>{{tr.dataTipoAviso}}</small></vs-td>
                            <vs-td :data="tr.enviarEmail"><small>{{tr.enviarEmail}}</small>
                                <template slot="edit">                                                                        
                                    <vs-radio v-model="tr.enviarEmail" vs-name="tr.enviarEmail" vs-value="SIM" class="mr-4"><small>SIM</small></vs-radio>
                                    <vs-radio v-model="tr.enviarEmail" vs-name="tr.enviarEmail" vs-value="NAO"><small>NÃO</small></vs-radio>
                                </template>
                            </vs-td>
                            <vs-td :data="tr.enviarSMS"><small>{{tr.enviarSMS}}</small>
                                <template slot="edit">                                                                        
                                    <vs-radio v-model="tr.enviarSMS" vs-name="tr.enviarSMS" vs-value="SIM" class="mr-4"><small>SIM</small></vs-radio>
                                    <vs-radio v-model="tr.enviarSMS" vs-name="tr.enviarSMS" vs-value="NAO"><small>NÃO</small></vs-radio>
                                </template>
                            </vs-td>
                            <vs-td :data="tr.enviarCentralMonitoramento"><small>{{tr.enviarCentralMonitoramento}}</small>
                                <template slot="edit">                                                                        
                                    <vs-radio v-model="tr.enviarCentralMonitoramento" vs-name="tr.enviarCentralMonitoramento" vs-value="SIM" class="mr-4"><small>SIM</small></vs-radio>
                                    <vs-radio v-model="tr.enviarCentralMonitoramento" vs-name="tr.enviarCentralMonitoramento" vs-value="NAO"><small>NÃO</small></vs-radio>
                                </template>
                            </vs-td>
                            <vs-td :data="tr.enviarPush"><small>{{tr.enviarPush}}</small>
                                <template slot="edit">                                                                        
                                    <vs-radio v-model="tr.enviarPush" vs-name="tr.enviarPush" vs-value="SIM" class="mr-4"><small>SIM</small></vs-radio>
                                    <vs-radio v-model="tr.enviarPush" vs-name="tr.enviarPush" vs-value="NAO"><small>NÃO</small></vs-radio>
                                </template>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </vs-tab>
            <vs-tab :label="'Anexos (' + dadosDaGridAnexos.length + ')'" v-if="data_local.id">
                <div class="vx-row">
                    <div class="vx-col md:w-3/4 sm:w-3/4 w-full">
                        <vs-button size="small" class="mt-2 mb-4" @click="copiarAnexosPreCadastro" color="primary" icon="send">COPIAR ANEXOS PRÉ-CADASTRO</vs-button> 
                        <vx-card class="mt-2" v-if="dadosDaGridAnexos.length">
                            <div class="flex items-center flex-start">
                                <vs-button class="mt-2 mr-2 mb-4" size="small" @click="verDocumento" color="primary" icon="search">VER</vs-button> 
                                <vs-button class="mt-2 mb-4" size="small" @click="excluirAnexo" color="danger" icon="delete">EXCLUIR</vs-button> 
                            </div>
                            
                            <ag-grid-vue
                                ref="agGridTableAnexos"        
                                :gridOptions="configuracaoGridAnexo.gridOptions"
                                class="ag-grid-table ag-grid-altura"
                                :columnDefs="configuracaoGridAnexo.columnDefs"
                                :defaultColDef="configuracaoGridAnexo.defaultColDef"
                                :rowData="dadosDaGridAnexos"
                                :headerHeight="25"
                                :rowHeight="25"
                                rowSelection="single"
                                @grid-ready="onGridReadyAnexo"                            
                                colResizeDefault="shift"                    
                                :animateRows="true"
                                :floatingFilter="false"
                                :pagination="false"
                                :paginationAutoPageSize="false"
                                :suppressPaginationPanel="true"
                                >
                            </ag-grid-vue>
                            
                            
                        </vx-card> 
                    </div>
                    <div class="vx-col md:w-1/4 sm:w-1/4 w-full flex-items-center">
                        <vx-card class="mt-2">
                            <vs-upload :action="getUrlPost" @on-success="successUploadFile" @on-delete="limparMsgErroUpload"
                                    text="Anexar ..." :data="dadosUpload" limit="1" multiple="false"  fileName="nomeArquivo"
                                    automatic single-upload/>
                            <vs-alert closable title="Atenção" color="warning" :active.sync="exibirMensagemResultadoUpload">{{mensagemResultadoUpload}}</vs-alert>
                        </vx-card> 
                    </div>
                </div>
            </vs-tab>
        </vs-tabs>
       
    </vx-card>  
  </div>
</template>

<script>
import axios from "@/axios.js"
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import { AgGridVue } from "ag-grid-vue"
import '@/assets/concept/scss/tabelaBasica.scss'
import { CONSTANTE_TIPO_CLIENTE } from '@/constantesComboBox.js'
import { CONSTANTE_STATUS_CLIENTE } from '@/constantesComboBox.js'
import { CONSTANTE_UFS } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_VERSOES } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_CONTRATO_TESTE } from '@/constantesComboBox.js'
import { CONSTANTE_STATUS_CONTRATO } from '@/constantesComboBox.js'
import { CONSTANTE_TIPO_CONTRATO } from '@/constantesComboBox.js'
import { CONSTANTE_ACAO_FIM_TESTE_CLIENTE } from '@/constantesComboBox.js'
import { CONSTANTE_STATUS_TESTE_CLIENTE } from '@/constantesComboBox.js'
import { CONSTANTE_TIPOS_AVISO } from '@/constantesComboBox.js'
import { CONSTANTE_REGRA_FATURAMENTO_CLIENTE } from '@/constantesComboBox.js'
import generico from "@/generico.js"

const URL_SERVLET = "/ClientesRest";
const NAME_ROUTE_SEARCH = "cadastro-clientes-pesquisar";

export default {   
  components: {  
    AgGridVue,
    flatPickr
  },  
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
            return {codigoInterno: null, tipoCliente: "PJ", CNPJCPF: null, razaoSocial: null, nome: null, endereco: null, cidade: null, estado: null, cep: null, telefone: null,
            telefone2: null, telefone3: null, email: null, email2: null, email3: null,  status: 'ATIVO', 
            loginServicoSMS: null, senhaServicoSMS: null,  senha: null, senhaBloqueio: null, senhaWebservice: null, 
            versaoProduto: 'BASIC', temControleFrota: false, temControlePneus: false, temControleViagem: false, temRoteirizacao: false,
            monitoravelPorCentral: false, enviarEmailAvisoEmLote: true, incrementoGMT: 0,  notaMaximaDeMotoristas: 100, 
            cargaHorariaDiariaTrabalhoMotorista: 8, 
            mensagem: null, textoAssinaturaMinhaRotaPuro: null, numeracaoPedidoPorData: false,
            statusContrato: 'ATIVO', tipoContrato: null, dataInicioContratoEmMili: null, duracaoContrato: null, diaVencimento: null, 
            nomeRepresentante: null, cpfRepresentante: null, emailAceiteFormularioPedido: null,
            statusTeste: null, acaoFimTeste: null, dataFimTesteEmMili: null, innerLista:[], bloquearCriacaoOrdemServico: true, 
            nomeResponsavelFrota: null, telefonesResponsavelFrota: null, emailResponsavelFrota: null,
            descontarTempoRefeicaoDaDirecao: true, descontarTempoDescansoDaDirecao:true, descontarTempoEsperaDaDirecao:true, 
            descontarTempoAbastecimentoDaDirecao:true, descontarTempoManutencaoDaDirecao:true, somarTempoEsperaADirecao:false,
            numeroMaximoHorasExtras: null, regraFaturamento: 'NO_VENCIMENTO', permiteNotaDuplicadaEmDespesa: true,
            numeroEndereco: null, bairro: null, nomeContato1: null, nomeContato2: null, nomeContato3: null, complementoEndereco : null, 
            permitirAlterarIButtons: false, idTecnicoPreferencial: null}
       }
    },
  },
  data() {
    return {        
        novaSenha: null,    
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)),   
        tipoContratoTeste : 'contrato',
        configDatePicker: {          
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "U", 
            altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
            locale: PortugueseLocale
        },
        dadosDaGridAnexos: [],
        configuracaoGridAnexo: { 
          gridApi: null, gridOptions: {}, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [                      
            { headerName: 'ID',  field: 'id', width: 30},        
            { headerName: 'Arquivo',  field: 'nomeDocumento', width: 400},        
            { headerName: 'Criado às',  field: 'dataUpload', width: 200}            
          ]
        },
        dadosUpload: {
           clientId: this.registroOriginal.id,                    
           destino: "cliente",
           empresaId: this.$store.state.AppActiveUser.idEmpresa,            
           outputFormat: 'JSON',
           nomeArquivo: 'Teste seção 2'
        },
        mensagemResultadoUpload: null,
        exibirMensagemResultadoUpload: false,
        toggleBtnEmail: 'SIM',
        toggleBtnSMS: 'SIM',
        toggleBtnCentral: 'SIM',
        toggleBtnPush: 'SIM',
        popImportarCliente : { exibir: false },
        popImportarPreCadastro : { exibir: false },
        listaTecnicos: [],
    }
  },  
  created(){
      this.CONSTANTE_TIPO_CLIENTE = CONSTANTE_TIPO_CLIENTE;
      this.CONSTANTE_STATUS_CLIENTE = CONSTANTE_STATUS_CLIENTE;
      this.CONSTANTE_UFS = CONSTANTE_UFS;
      if (this.$store.state.AppActiveUser.idEmpresa === 1) {
            this.CONSTANTE_TIPO_VERSOES = CONSTANTE_TIPO_VERSOES.concat([ {label:"Fast", data:"FAST"}]);
      } else {
            if (this.$store.state.AppActiveUser.acessoVersaoPRO) {
                this.CONSTANTE_TIPO_VERSOES = CONSTANTE_TIPO_VERSOES;
            } else {
                this.CONSTANTE_TIPO_VERSOES = [{label:"Basic", data:"BASIC"}];
            }
            
      }
      this.CONSTANTE_TIPO_CONTRATO_TESTE = CONSTANTE_TIPO_CONTRATO_TESTE;
      this.CONSTANTE_STATUS_CONTRATO = CONSTANTE_STATUS_CONTRATO;
      this.CONSTANTE_TIPO_CONTRATO = CONSTANTE_TIPO_CONTRATO;      
      this.CONSTANTE_STATUS_TESTE_CLIENTE = CONSTANTE_STATUS_TESTE_CLIENTE;
      this.CONSTANTE_ACAO_FIM_TESTE_CLIENTE = CONSTANTE_ACAO_FIM_TESTE_CLIENTE;
      this.CONSTANTE_TIPOS_AVISO = CONSTANTE_TIPOS_AVISO;
      this.CONSTANTE_REGRA_FATURAMENTO_CLIENTE = CONSTANTE_REGRA_FATURAMENTO_CLIENTE;
  }, 
  mounted() {
      this.fetchListaTecnicos();
      if (this.registroOriginal.hasOwnProperty('id')) {
          this.findById();
      } else {
          this.data_local.senhaBloqueio = this.getRandomInt(10000,99999);
          this.data_local.textoAssinaturaMinhaRotaPuro = 'Atesto para os devidos fins que as mercadorias recebidas condizem com aqueles previstos na nota fisca n. <NoNF> todas em conformidade com aquilo por mim solicitado, não havendo o que reclamar com relação a esta entrega específica.';
          this.data_local.innerLista = this.CONSTANTE_TIPOS_AVISO;
      }
  },
  computed: {        
    isDeletable() {
       return this.data_local != null && this.data_local.hasOwnProperty("id");
    },
    getUrlPost() {
      return axios.defaults.baseURL + "/UploadDocumentos?processUploadedFile";
    },    
    temPermissaoLiberarCriacaoOS() {
        let perfilLogado = this.$store.state.AppActiveUser.perfil;
        if (perfilLogado === "EMPRESA" || perfilLogado === 'ADMINISTRADOR' || perfilLogado === 'FINANCEIRO'  ||
               perfilLogado === 'EMPRESA_SUPORTE_INTERNO_CONCEPT') {
            return true;
        }

        return false;
    },
    habilitarGravacao() {
          let perfilLogado = this.$store.state.AppActiveUser.perfil;
          if (perfilLogado === "ATIVADOR" || perfilLogado === "CONFIGURADOR" || 
              perfilLogado === "MONITOR_PANICO" || perfilLogado === "EMPRESA_MONITOR_PANICO" || perfilLogado === "EMPRESA_ATIVADOR") {
              return false;
          }

          return true;
      }
  },
  methods: {  
    toggleBtnEmail_click() {
        let novoValor = 'SIM';
        if (this.toggleBtnEmail === 'SIM') {
            this.toggleBtnEmail = 'NÃO';            
            novoValor = 'SIM';
        } else {
            this.toggleBtnEmail = 'SIM';
            novoValor = 'NAO';
        }

        for (var i=0; i < this.data_local.innerLista.length; i++) {
            this.$set(this.data_local.innerLista[i], 'enviarEmail', novoValor);
        }
    },
    toggleBtnSMS_click() {
        let novoValor = 'SIM';
        if (this.toggleBtnSMS === 'SIM') {
            this.toggleBtnSMS = 'NÃO';            
            novoValor = 'SIM';
        } else {
            this.toggleBtnSMS = 'SIM';
            novoValor = 'NAO';
        }

        for (var i=0; i < this.data_local.innerLista.length; i++) {
            this.$set(this.data_local.innerLista[i], 'enviarSMS', novoValor);
        }
    },
    toggleBtnCentral_click() {
        let novoValor = 'SIM';
        if (this.toggleBtnCentral === 'SIM') {
            this.toggleBtnCentral = 'NÃO';            
            novoValor = 'SIM';
        } else {
            this.toggleBtnCentral = 'SIM';
            novoValor = 'NAO';
        }

        for (var i=0; i < this.data_local.innerLista.length; i++) {
            this.$set(this.data_local.innerLista[i], 'enviarCentralMonitoramento', novoValor);
        }
    },
    toggleBtnPush_click() {
        let novoValor = 'SIM';
        if (this.toggleBtnPush === 'SIM') {
            this.toggleBtnPush = 'NÃO';            
            novoValor = 'SIM';
        } else {
            this.toggleBtnPush = 'SIM';
            novoValor = 'NAO';
        }

        for (var i=0; i < this.data_local.innerLista.length; i++) {
            this.$set(this.data_local.innerLista[i], 'enviarPush', novoValor);
        }
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    },
    findById() {     
      this.$vs.loading();
      const params = new URLSearchParams();
      params.append('method', 'FindById');
      params.append('outputFormat', 'JSON');
      params.append('id', this.data_local.id);

      axios.post(URL_SERVLET, params, {              
              credentials: 'include',
              responseEncoding: 'iso88591',
              withCredentials: true } )
      .then((response) => {
          this.$vs.loading.close();
          if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
              this.$router.push({ path: '/' });
          } else {
              this.data_local = response.data;  
              if (response.data.hasOwnProperty('dataInicioContratoEmMili')) { 
                    this.data_local.dataInicioContratoEmMili  = String(response.data.dataInicioContratoEmMili / 1000);
              }

              if (response.data.hasOwnProperty('dataFimTesteEmMili')) { 
                    this.data_local.dataFimTesteEmMili  = String(response.data.dataFimTesteEmMili / 1000);
              }

              for (var i=0; i < this.data_local.innerLista.length; i++) {
                  for (var j=0; j < this.CONSTANTE_TIPOS_AVISO.length; j++) {
                      if (this.data_local.innerLista[i].dataTipoAviso === this.CONSTANTE_TIPOS_AVISO[j].dataTipoAviso) {
                          this.$set(this.data_local.innerLista[i], 'labelTipoAviso', this.CONSTANTE_TIPOS_AVISO[j].labelTipoAviso);
                      }
                  }
              }
              this.fetchListaDocumentos();                  
          }
      })
      .catch((error) => { 
          this.$vs.loading.close();
          /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
      })    
                        
    },
    excluir() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir ?', acceptText: 'Sim', cancelText: 'Não', accept: this.excluirRequest
        });
    },
    excluirRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('method', 'Delete');      
        params.append('id', this.data_local.id);   
                
        axios.post(URL_SERVLET, params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },
    gravar() {        
        if (this.formularioValido()) { 
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            
            if (this.registroOriginal.hasOwnProperty("id")) {
                params.append('method', 'Update');      
                params.append('id', this.data_local.id);      
            }  else {
                params.append('method', 'Insert');      
            }  
            
            if (this.data_local.codigoInterno) {
                params.append('codigoInterno', this.data_local.codigoInterno);   
            }
            params.append('nome', this.data_local.nome);      
            params.append('razaoSocial', this.data_local.razaoSocial);      
            params.append('CNPJCPF', this.data_local.CNPJCPF);
            params.append('login', this.data_local.CNPJCPF);
            params.append('status', this.data_local.status);
            
            if (this.data_local.novaSenha) {
                params.append('senha', this.data_local.novaSenha);
            }

            params.append('tipoCliente', this.data_local.tipoCliente);
            params.append('versaoProduto', this.data_local.versaoProduto);
            params.append('telefone', this.data_local.telefone);     
            if (this.data_local.telefone2) {   
                params.append('telefone2', this.data_local.telefone2);     
            }

            if (this.data_local.telefone3) {   
                params.append('telefone3', this.data_local.telefone3);     
            }
            params.append('email', this.data_local.email);  
            if (this.data_local.email2) {   
                params.append('email2', this.data_local.email2);     
            }
            if (this.data_local.email3) {   
                params.append('email3', this.data_local.email3);     
            }

            params.append('senhaBloqueio', this.data_local.senhaBloqueio);     
            params.append('endereco', this.data_local.endereco);     
            params.append('cidade', this.data_local.cidade);     
            params.append('estado', this.data_local.estado);     
            params.append('cep', this.data_local.cep);     
            if (this.data_local.numeroEndereco) {
                params.append('numeroEndereco', this.data_local.numeroEndereco);
            }
            if (this.data_local.bairro) {
                params.append('bairro', this.data_local.bairro);
            }
            if (this.data_local.nomeContato1) {   
                params.append('nomeContato1', this.data_local.nomeContato1);
            }
            if (this.data_local.nomeContato2) {   
                params.append('nomeContato2', this.data_local.nomeContato2);
            }
            if (this.data_local.nomeContato3) {   
                params.append('nomeContato3', this.data_local.nomeContato3);
            }
            if (this.data_local.complementoEndereco) {   
                params.append('complementoEndereco', this.data_local.complementoEndereco);
            }
            params.append('senhaBloqueio', this.data_local.senhaBloqueio);     
            params.append('monitoravelPorCentral', this.data_local.monitoravelPorCentral);     
            params.append('enviarEmailAvisoEmLote', this.data_local.enviarEmailAvisoEmLote);     
            params.append('incrementoGMT', this.data_local.incrementoGMT); 
            params.append('textoAssinaturaMinhaRota', this.data_local.textoAssinaturaMinhaRotaPuro);             
            params.append('numeracaoPedidoPorData', this.data_local.numeracaoPedidoPorData);
            params.append('notaMaximaDeMotoristas', this.data_local.notaMaximaDeMotoristas);
            params.append('cargaHorariaDiariaTrabalhoMotorista', this.data_local.cargaHorariaDiariaTrabalhoMotorista);

            if (this.tipoContratoTeste === 'teste') {
                params.append('statusTeste', this.data_local.statusTeste);
                params.append('dataFimTeste', this.data_local.dataFimTesteEmMili*1000);
                params.append('acaoFimTeste', this.data_local.acaoFimTeste);
            } else {
                params.append('statusContrato', this.data_local.statusContrato);
                params.append('tipoContrato', this.data_local.tipoContrato);
                params.append('duracaoContrato', this.data_local.duracaoContrato);
                params.append('diaVencimento', this.data_local.diaVencimento);                
                params.append('dataInicioContrato', this.data_local.dataInicioContratoEmMili*1000);
            }

            if (this.data_local.versaoProduto === 'PRO') {
                params.append('temControleFrota', this.data_local.temControleFrota);
                params.append('temControlePneus', this.data_local.temControlePneus);
                params.append('temControleViagem', this.data_local.temControleViagem);
                params.append('temRoteirizacao', this.data_local.temRoteirizacao);
            } 

            if (this.data_local.mensagem) {
                params.append('mensagem', this.data_local.mensagem);
            }

            params.append('bloquearCriacaoOrdemServico', this.data_local.bloquearCriacaoOrdemServico);

            if (this.data_local.nomeRepresentante) {
                params.append('nomeRepresentante', this.data_local.nomeRepresentante);
            }
            if (this.data_local.cpfRepresentante) {
                params.append('cpfRepresentante', this.data_local.cpfRepresentante);
            }
            if (this.data_local.emailAceiteFormularioPedido) {
                params.append('emailAceiteFormularioPedido', this.data_local.emailAceiteFormularioPedido);
            }

            if (this.data_local.nomeResponsavelFrota) {
                params.append('nomeResponsavelFrota', this.data_local.nomeResponsavelFrota);
            }
            if (this.data_local.telefonesResponsavelFrota) {
                params.append('telefonesResponsavelFrota', this.data_local.telefonesResponsavelFrota);
            }
            if (this.data_local.emailResponsavelFrota) {
                params.append('emailResponsavelFrota', this.data_local.emailResponsavelFrota);
            }

            params.append('descontarTempoRefeicaoDaDirecao', this.data_local.descontarTempoRefeicaoDaDirecao);
            params.append('descontarTempoDescansoDaDirecao', this.data_local.descontarTempoDescansoDaDirecao);
            params.append('descontarTempoEsperaDaDirecao', this.data_local.descontarTempoEsperaDaDirecao);
            params.append('descontarTempoAbastecimentoDaDirecao', this.data_local.descontarTempoAbastecimentoDaDirecao);
            params.append('descontarTempoManutencaoDaDirecao', this.data_local.descontarTempoManutencaoDaDirecao);
            params.append('somarTempoEsperaADirecao', this.data_local.somarTempoEsperaADirecao);
            if (this.data_local.numeroMaximoHorasExtras) {
                params.append('numeroMaximoHorasExtras', this.data_local.numeroMaximoHorasExtras);
            }   

            params.append("JSONItems", JSON.stringify(this.data_local.innerLista)); 
            if (this.data_local.regraFaturamento) {
                params.append('regraFaturamento', this.data_local.regraFaturamento);
            }
            params.append("permiteNotaDuplicadaEmDespesa", this.data_local.permiteNotaDuplicadaEmDespesa);
            params.append("permitirAlterarIButtons", this.data_local.permitirAlterarIButtons);

            if (this.data_local.idTecnicoPreferencial) {                
                params.append("idTecnicoPreferencial", this.data_local.idTecnicoPreferencial);
            }
                        
            axios.post(URL_SERVLET, params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    /* eslint-disable no-console */ console.log(response.data);
                    if (response.data.hasOwnProperty("error")) {
                            this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                    } else {
                        this.$router.push({ name: NAME_ROUTE_SEARCH })
                    }                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        }
         
    },
    voltar() {
        this.$router.push({
            name: NAME_ROUTE_SEARCH                     
        })
    },
    formularioValido() {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];

        if (!this.data_local.CNPJCPF) {
            this.errorsValidacao.push("CPF/CNPJ obrigatório");
        }

        if (!this.data_local.status) {
            this.errorsValidacao.push("Status obrigatório");
        } 

        if (!this.data_local.razaoSocial) {
            this.errorsValidacao.push("Razão Social obrigatório");
        }

        if (!this.data_local.nome) {
            this.errorsValidacao.push("Nome obrigatório");
        }

        if (!this.data_local.endereco) {
            this.errorsValidacao.push("Endereço obrigatório");
        }

        if (!this.data_local.cidade) {
            this.errorsValidacao.push("Cidade obrigatório");
        }

        if (!this.data_local.estado) {
            this.errorsValidacao.push("Estado obrigatório");
        }

        if (this.$store.state.AppActiveUser.idEmpresa === 1) {
            if (!this.data_local.cep) {
                this.errorsValidacao.push("CEP obrigatório");
            } else if (!generico.isCEPValido(this.data_local.cep)) {
                this.errorsValidacao.push("CEP inválido");
            }
        }

        if (!this.data_local.telefone) {
            this.errorsValidacao.push("Telefone obrigatório");
        }

        if (!this.data_local.email) {
            this.errorsValidacao.push("Email obrigatório");
        } else if (generico.emailInvalido(this.data_local.email)) {
            this.errorsValidacao.push("Email inválido");   
        }

        if (this.data_local.email2 && generico.emailInvalido(this.data_local.email2)) {
            this.errorsValidacao.push("Email 2 inválido");   
        }

        if (this.data_local.email3 && generico.emailInvalido(this.data_local.email3)) {
            this.errorsValidacao.push("Email 3 inválido");   
        }

        if (!this.data_local.senhaBloqueio) {
            this.errorsValidacao.push("Senha de bloqueio");
        } else if (this.data_local.senhaBloqueio.length < 4) {
            this.errorsValidacao.push("Senha de bloqueio muito curta");
        }

        if (!this.data_local.versaoProduto) {
            this.errorsValidacao.push("Versão obrigatório");
        } 

        if (this.tipoContratoTeste === 'contrato') {
            if (!this.data_local.statusContrato) {
                this.errorsValidacao.push("Status do Contrato obrigatório");
            }
            
            if (!this.data_local.tipoContrato) {
                this.errorsValidacao.push("Tipo do Contrato obrigatório");
            }

            if (!this.data_local.dataInicioContratoEmMili) {
                this.errorsValidacao.push("Data de Início do Contrato obrigatório");
            }

            if (!this.data_local.duracaoContrato) {
                this.errorsValidacao.push("Duração do Contrato em meses obrigatório");
            }

            if (!this.data_local.diaVencimento) {
                this.errorsValidacao.push("Dia do Vencimento no Contrato obrigatório");
            } else if (this.data_local.diaVencimento < 1 || this.data_local.diaVencimento > 30) {
                this.errorsValidacao.push("Dia do Vencimento no Contrato deve ser entre 1 e 30");
            }
        } else {
            if (!this.data_local.statusTeste) {
                this.errorsValidacao.push("Status do Teste obrigatório");
            }
            if (!this.data_local.acaoFimTeste) {
                this.errorsValidacao.push("Ação para o fim do teste obrigatório");
            }
            if (!this.data_local.dataFimTesteEmMili) {
                this.errorsValidacao.push("Data de Fim do Teste obrigatório");
            }
        }
        
        if (!this.data_local.loginServicoSMS && this.data_local.senhaServicoSMS) {
            this.errorsValidacao.push("Informe o login do serviço de SMS.");
        }

        if (this.data_local.loginServicoSMS && !this.data_local.senhaServicoSMS) {
            this.errorsValidacao.push("Informe a senha do serviço de SMS.");
        }

        if (this.data_local.incrementoGMT < -9 || this.data_local.incrementoGMT > 9) {
            this.errorsValidacao.push("Incremento GMT deve ser entre -9 e 9.");
        }

         if (!this.data_local.textoAssinaturaMinhaRotaPuro) {
            this.errorsValidacao.push("Texto para assinatura do App Minha Rota obrigatório");
        } 

        let perfilLogado = this.$store.state.AppActiveUser.perfil;
        
        if (perfilLogado === "ATIVADOR" || perfilLogado === "CONFIGURADOR" || perfilLogado === "SUPERVISOR" || perfilLogado === "FINANCEIRO" || perfilLogado === "MONITOR_PANICO") {  
            
            let isContrato = this.registroOriginal.dataInicioContratoEmMili !== null;

            if (isContrato && this.tipoContratoTeste === 'teste') {
                this.errorsValidacao.push("Você não tem permissão para alterar um cliente que está com contrato ativo para em teste.");		
            }

            if (isContrato && this.registroOriginal.statusContrato && this.data_local.statusContrato !== this.registroOriginal.statusContrato) {
                this.errorsValidacao.push("Você não tem permissão para alterar um cliente que está com contrato ativo para em teste.");
            }
        }

        if (this.$store.state.AppActiveUser.idEmpresa === 1) {
            if (!this.data_local.nomeResponsavelFrota) {
                this.errorsValidacao.push("Nome do responsável pela frota obrigatório");
            }
        }

        if (this.$store.state.AppActiveUser.idEmpresa === 1) {
            if (!this.data_local.telefonesResponsavelFrota) {
                this.errorsValidacao.push("Telefone do responsável pela frota obrigatório");
            }
        }
        if (this.$store.state.AppActiveUser.idEmpresa === 1) {
            if (!this.data_local.emailResponsavelFrota) {
                this.errorsValidacao.push("Email contato frota obrigatório");
            } else if (this.data_local.emailResponsavelFrota && generico.emailInvalido(this.data_local.emailResponsavelFrota)) {
                this.errorsValidacao.push("Email do responsável pela frota inválido");   
            }
        }

        if (!this.errorsValidacao.length) {
            return true;
        }   
        
      return false;
      
    },
    successUploadFile(evento){              
        var resultadoJSON = JSON.parse(evento.target.response);
        this.mensagemResultadoUpload = resultadoJSON.mensagem;
        this.exibirMensagemResultadoUpload = true;
        this.fetchListaDocumentos(); 
    },
    limparMsgErroUpload() {
      this.mensagemResultadoUpload = null;
      this.exibirMensagemResultadoUpload = false;
    },
    verDocumento() { 
        var itemSelecionados = this.configuracaoGridAnexo.gridApi.getSelectedRows();

        if (itemSelecionados && itemSelecionados.length > 0) {
            let ultimoIndice = itemSelecionados[0].nomeDocumento.lastIndexOf(".") ;
            if ( ultimoIndice != -1) {
                window.open("https://documentossetup.s3.amazonaws.com/" + this.registroOriginal.id + "/" + itemSelecionados[0].id + "." + itemSelecionados[0].nomeDocumento.substring(ultimoIndice+1), "_blank");    
            } else {
                window.open("https://documentossetup.s3.amazonaws.com/" + this.registroOriginal.id + "/" + itemSelecionados[0].id, "_blank");    
            }
            
        } else {
            this.$vs.dialog({ color: 'danger', title: 'Erro', text: 'Selecione um anexo', acceptText: 'OK'})
        }
    },
    fetchListaDocumentos() {
        const params = new URLSearchParams();
        params.append('method', 'LISTAR_DOCUMENTOS');
        params.append('outputFormat', 'JSON');
        params.append('id', this.registroOriginal.id);

        axios.post(URL_SERVLET, params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {                             
                this.dadosDaGridAnexos = response.data;    
        })
        .catch((error) => { 
            /* eslint-disable no-console */ console.log("Erro fetchListaDocumentos " + error);        
        })      
    },  
    onGridReadyAnexo() {
       this.configuracaoGridAnexo.gridApi = this.configuracaoGridAnexo.gridOptions.api;
       this.configuracaoGridAnexo.gridApi.sizeColumnsToFit();      
    },    
    excluirAnexo(){        
        this.$vs.dialog({
            type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir o arquivo ?  '
            , acceptText: 'Sim', cancelText: 'Não', accept: this.excluirAnexoRequest
        });
    },
    excluirAnexoRequest() {
        var itemSelecionados = this.configuracaoGridAnexo.gridApi.getSelectedRows();

        if (itemSelecionados && itemSelecionados.length > 0) {
            let currentObj = this;
            this.$vs.loading(); 

            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');                
            params.append('idDocumento', itemSelecionados[0].id);   
            params.append('nomeDocumento', itemSelecionados[0].nomeDocumento);   
            params.append('idCliente', this.registroOriginal.id);
            params.append('method', 'EXCLUIR_DOCUMENTOS');      
                    
            axios.post(URL_SERVLET, params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                    this.$router.push({ path: '/' });
                } else {
                    currentObj.$vs.loading.close();

                    if (response.data.mensagem.indexOf("erro") != -1) {
                            this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.mensagem, acceptText: 'OK'})
                    } else {
                        this.fetchListaDocumentos();
                    }                   
                }
            })
            .catch((error) => { 
                currentObj.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        } else {
            this.$vs.dialog({ color: 'danger', title: 'Erro', text: 'Selecione um anexo', acceptText: 'OK'})
        }
    },  
    copiarAnexosPreCadastro() {        
        let currentObj = this;
        this.$vs.loading(); 

        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');                        
        params.append('idCliente', this.registroOriginal.id);
        params.append('CNPJCPF', this.registroOriginal.CNPJCPF);
        params.append('method', 'COPIAR_DOCUMENTOS_PRE_CADASTRO');      
                
        axios.post(URL_SERVLET, params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                this.$router.push({ path: '/' });
            } else {
                currentObj.$vs.loading.close();

                if (response.data.mensagem.indexOf("erro") != -1) {
                    this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.mensagem, acceptText: 'OK'})
                } else {
                    this.$vs.dialog({ color: 'success', title: 'Atenção', text: response.data.mensagem, acceptText: 'OK'})
                    this.fetchListaDocumentos();
                }   

            }
        })
        .catch((error) => { 
            currentObj.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
        
    },    
    enviarEmailSenhaBloqueio() {
        this.$vs.loading();
        const params = new URLSearchParams();
        params.append('method', 'ENVIAR_SENHAS_BLOQUEIO');
        params.append('outputFormat', 'JSON');
        params.append('id', this.registroOriginal.id);
        params.append('CNPJCPF', this.registroOriginal.CNPJCPF);

        axios.post(URL_SERVLET, params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {     
            this.$vs.loading.close();                        
            if (!response.data.hasOwnProperty('mensagem') ) {
                this.$vs.dialog({color:'danger', title: 'Erro',  text: response.data.error, acceptText: 'Fechar' });  
            } else {
                this.$vs.dialog({color:'success', title: 'Aviso',  text: response.data.mensagem, acceptText: 'Fechar' });  
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("Erro enviarEmailSenhaBloqueio " + error);        
        })      
    },  
    reenviarEmailBoaVindas() {
        this.$vs.loading();
        const params = new URLSearchParams();
        params.append('method', 'ENVIAR_EMAIL_BOAS_VINDAS');
        params.append('outputFormat', 'JSON');
        params.append('id', this.registroOriginal.id);
        params.append('CNPJCPF', this.registroOriginal.CNPJCPF);

        axios.post(URL_SERVLET, params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {     
            this.$vs.loading.close();                        
            if (!response.data.hasOwnProperty('mensagem') ) {
                this.$vs.dialog({color:'danger', title: 'Erro',  text: response.data.error, acceptText: 'Fechar' });  
            } else {
                this.$vs.dialog({color:'success', title: 'Aviso',  text: response.data.mensagem, acceptText: 'Fechar' });  
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("Erro enviarEmailSenhaBloqueio " + error);        
        })      
    },  
    abrirPopUpImportarCliente(){        
        this.popImportarCliente.exibir = true;
    },
    importarCliente(pCpfCNPJ) {
        this.$vs.loading();
        const params = new URLSearchParams();
        params.append('method', 'ENVIAR_SENHAS_BLOQUEIO');
        params.append('outputFormat', 'JSON');
        params.append('cpfcnpj', pCpfCNPJ);

        axios.post("/ImportarClienteERP", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {    
            this.popImportarCliente.exibir = false;
            this.$vs.loading.close();                        
            if (response.data.hasOwnProperty('mensagem') ) {
                this.$vs.dialog({color:'danger', title: 'Erro',  text: response.data.mensagem, acceptText: 'Fechar' });  
            } else {
                this.$refs.txtCPFCNPJ.value = "";
                this.$delete(this.data_local, 'id');
                this.data_local.razaoSocial = response.data.razaoSocial;
                this.data_local.nome = response.data.nome;
                this.data_local.cpfCNPJ = response.data.cpfCNPJ;
                this.data_local.telefone = response.data.telefone1;
                this.data_local.telefone2 = response.data.telefone2;
                this.data_local.telefone3 = response.data.telefone3;
                this.data_local.email = response.data.email1;
                this.data_local.email2 = response.data.email2;
                this.data_local.email3 = response.data.email3;

                this.data_local.endereco = response.data.endereco;
                this.data_local.cidade = response.data.cidade;
                this.data_local.estado = response.data.estado;
                this.data_local.cep = response.data.cep;

                this.data_local.numeroEndereco = response.data.numeroEndereco;
                this.data_local.bairro = response.data.bairro;                
                this.data_local.complementoEndereco = response.data.complementoEndereco;
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("Erro enviarEmailSenhaBloqueio " + error);        
        })      
    },
    abrirPopUpImportarPreCadastro(){        
        this.popImportarPreCadastro.exibir = true;
    },
    importarPreCadastro(pCpfCNPJ) {
        const params = new URLSearchParams();        
        params.append('CNPJCPF', pCpfCNPJ);     
        
        axios.post("/rest/PreCadastro/Recuperar", params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                this.$router.push({ path: '/login' });                       
            } else {                                
                this.data_local.CNPJCPF = response.data.PreCadastro.CNPJCPF;
                this.data_local.razaoSocial = response.data.PreCadastro.razaoSocial;
                this.data_local.nome = response.data.PreCadastro.nome;
                this.data_local.endereco = response.data.PreCadastro.endereco;
                this.data_local.numeroEndereco = response.data.PreCadastro.numeroEndereco;
                this.data_local.complementoEndereco = response.data.PreCadastro.complementoEndereco;
                this.data_local.bairro = response.data.PreCadastro.bairro;
                this.data_local.cidade = response.data.PreCadastro.cidade;
                this.data_local.estado = response.data.PreCadastro.estado;
                this.data_local.cep = response.data.PreCadastro.cep;
                this.data_local.nomeContato1 = response.data.PreCadastro.nomeContato1;
                this.data_local.nomeContato2 = response.data.PreCadastro.nomeContato2;
                this.data_local.nomeContato3 = response.data.PreCadastro.nomeContato3;
                this.data_local.telefone = response.data.PreCadastro.telefone;
                this.data_local.telefone2 = response.data.PreCadastro.telefone2;
                this.data_local.telefone3 = response.data.PreCadastro.telefone3;
                this.data_local.email = response.data.PreCadastro.email;
                this.data_local.email2 = response.data.PreCadastro.email2;
                this.data_local.email3 = response.data.PreCadastro.email3;
                this.data_local.senhaBloqueio = response.data.PreCadastro.senhaBloqueio;
                this.data_local.versaoProduto = response.data.PreCadastro.versaoProduto;
                this.data_local.temControleFrota = response.data.PreCadastro.temControleFrota;
                this.data_local.temControlePneus = response.data.PreCadastro.temControlePneus;
                this.data_local.temControleViagem = response.data.PreCadastro.temControleViagem;
                this.data_local.temRoteirizacao = response.data.PreCadastro.temRoteirizacao;
                this.data_local.tipoContrato = response.data.PreCadastro.tipoContrato;                
                this.data_local.duracaoContrato = response.data.PreCadastro.duracaoContrato;
                this.data_local.diaVencimento = response.data.PreCadastro.diaVencimento;
                this.data_local.nomeRepresentante = response.data.PreCadastro.nomeRepresentante;
                this.data_local.cpfRepresentante = response.data.PreCadastro.cpfRepresentante;
                this.data_local.emailAceiteFormularioPedido = response.data.PreCadastro.emailAceiteFormularioPedido;
                this.data_local.nomeResponsavelFrota = response.data.PreCadastro.nomeResponsavelFrota;
                this.data_local.emailResponsavelFrota = response.data.PreCadastro.emailResponsavelFrota;
                this.data_local.telefonesResponsavelFrota = response.data.PreCadastro.telefonesResponsavelFrota;
                
                this.data_local.dataInicioContratoEmMili = String(response.data.PreCadastro.dataInicioContratoEmSec);
                
                this.data_local.senhaWebservice = null;
                this.data_local.status = 'ATIVO';
                this.data_local.loginServicoSMS = null;
                this.data_local.senhaServicoSMS = null;
                this.data_local.senha = null;
                this.data_local.tipoCliente = "PJ";
                this.data_local.codigoInterno = null;
                this.data_local.monitoravelPorCentral = false;
                this.data_local.enviarEmailAvisoEmLote = true;
                this.data_local.statusContrato = 'ATIVO';
                this.data_local.incrementoGMT = 0;
                this.data_local.notaMaximaDeMotoristas = 100;
                this.data_local.cargaHorariaDiariaTrabalhoMotorista = 8;
                this.data_local.mensagem = null;
                this.data_local.textoAssinaturaMinhaRotaPuro = 'Atesto para os devidos fins que as mercadorias recebidas condizem com aqueles previstos na nota fisca n. <NoNF> todas em conformidade com aquilo por mim solicitado, não havendo o que reclamar com relação a esta entrega específica.';
                this.data_local.numeracaoPedidoPorData = false;
                this.data_local.statusTeste = null;
                this.data_local.acaoFimTeste = null;
                this.data_local.dataFimTesteEmMili = null;
                this.data_local.innerLista = this.CONSTANTE_TIPOS_AVISO;
                this.data_local.bloquearCriacaoOrdemServico = true;
                this.data_local.permitirAlterarIButtons = true;
                this.data_local.idTecnicoPreferencial = null;
                this.data_local.permiteNotaDuplicadaEmDespesa = true;
                   
            }
        })
        .catch((error) => { 
            /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    }, 
    fetchListaTecnicos() {     
        this.$vs.loading() 
        const params = new URLSearchParams();
        params.append('method', 'FindAll');
        params.append('orderDirection', 'ASC');
        params.append('outputFormat', 'JSON');
        
        axios.post("/TecnicoRest", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.listaTecnicos = response.data;                            
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
        })    
                            
    },
  }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 300px);
      position: relative;      
  } 
</style>

<style lang="scss" scoped>
  .ag-grid-altura{
    height: calc(100vh - 250px);
  }
</style>